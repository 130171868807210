import { SideBar } from "../../../components/Sidebar";
import { UserListCard } from "../../../components/UserListCard";

export function IncompleteDocumentation() {
  return (
    <>
      <SideBar />
      <UserListCard type="PENDENTE" title="Lojas Com a Documentação Incompleta" />
    </>
  );
}

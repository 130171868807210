import { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { BiSort } from "react-icons/bi";
import { BsChatSquareDots, BsChatSquareDotsFill } from "react-icons/bs";
import { FcAlphabeticalSortingAz } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import { Store } from "../../context/auth";
import { useMobile } from "../../context/windowSize";
import { api } from "../../service/api";
import { ChatModal } from "../ChatModal";
import { PaginationComponent } from "../Pagination";
import { StatusCadastro } from "../StatusCadastro";
import { UserInfoModal } from "../UserInfoModal";
import { UserListCardDropDown } from "../UserListCardDropDown";
import { GenericButton, OrderButton, SpinnerContainer, UserRow } from "./styles";

interface UserListCardProps {
  type: "ESPERANDO_FINANCEIRA" | "PENDENTE" | "COMPLETO" | "PRE_CADASTRO";
  title?: string;
}

export interface IStoresResponse {
  stores: {
    per_page: number;
    total: number;
    current_page: number;
    data: Store[];
  };
}

export function UserListCard({ type, title }: UserListCardProps) {
  const { isMobile } = useMobile();
  const history = useHistory();
  const [users, setUsers] = useState<Store[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [chatShow, setChatShow] = useState(false);
  const [infoModalUserId, setInfoModalUserId] = useState("");
  const [chatStoreId, setChatStoreId] = useState("");
  const controller = new AbortController();
  const [pages, setPages] = useState(0);
  const [orderBy, setOrderBy] = useState<"asc" | "desc" | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [isSearchTouched, setIsSearchTouched] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);

  const handleUserDetail = (userId: string) => {
    history.push(`/user/${userId}`);
  };

  const handleUserSalesman = (userId: string, username: string) => {
    history.push(`/salesman/${userId}/${username}`);
  };

  function handleCloseModal() {
    setModalShow(false);
  }
  function handleOpenModal(userId: string) {
    setInfoModalUserId(userId);
    setModalShow(true);
  }

  function handleCloseChat() {
    setChatShow(false);
  }

  function handleOpenChat(storeId: string) {
    setChatStoreId(storeId);
    setChatShow(true);
  }

  function handleChangePage(page: number) {
    setCurrentPage(page);
  }

  function handleFirstPage() {
    setCurrentPage(0);
  }

  function handleLastPage() {
    setCurrentPage(pages - 1);
  }

  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  function handlePrevPage() {
    setCurrentPage(currentPage - 1);
  }

  function handleLoading(loading: boolean) {
    setIsLoading(loading);
  }

  useEffect(() => {
    const loadUsers = async () => {
      const url = type === "PRE_CADASTRO" ? "/shopkeepers" : `store/status/${type}`;
      try {
        setIsLoading(true);

        const response = await api.get<IStoresResponse>(url, {
          params: {
            page: currentPage + 1,
            limit: 10,
            orderBy: orderBy || "",
            search,
          },
          signal: controller.signal,
        });
        const storesResponse = response.data.stores;
        if (!controller.signal.aborted) {
          setUsers(storesResponse.data);
          setPages(Math.ceil(storesResponse.total / storesResponse.per_page));
        }
      } catch (e) {
        if (!controller.signal.aborted) setIsLoading(false);
      } finally {
        if (!controller.signal.aborted) setIsLoading(false);
      }
    };

    if (type !== "PRE_CADASTRO" && chatShow === false) {
      loadUsers();
    }
    return () => controller.abort();
  }, [chatShow, currentPage, orderBy, search]);

  return (
    <>
      <UserInfoModal
        show={modalShow}
        onHide={handleCloseModal}
        onEscapeKeyDown={handleCloseModal}
        userId={infoModalUserId}
      />
      <ChatModal show={chatShow} storeId={chatStoreId} onHide={handleCloseChat} onEscapeKeyDown={handleCloseChat} />
      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <div className="col-12 mt-3">
          <div className="card p-2 d-flex flex-row-reverse">
            <div className="input-group">
              <input
                ref={searchRef}
                type="search"
                className="form-control"
                placeholder="Pesquisa"
                aria-label="Pesquisa"
                onChange={(e) => setIsSearchTouched(!!e.target.value)}
              />
              {!isSearchTouched ? null : (
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setIsSearchTouched(false);
                    if (searchRef.current) {
                      searchRef.current.value = "";
                      searchRef.current.focus();
                    }
                  }}
                >
                  Limpar
                </button>
              )}
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setSearch(searchRef.current?.value || "")}
              >
                Pesquisar
              </button>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "20px" }} className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{title || "Usuários registrados na Parcelize cadastrados na Via Certa"}</h3>
              </div>
              <div className="card-body table-responsive p-0">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Completo</th>
                      <th>CNPJ</th>
                      <th>
                        Nome Fantasia
                        <OrderButton
                          onClick={() => {
                            if (orderBy === "desc" || orderBy === null) {
                              setOrderBy("asc");
                            }
                            if (orderBy === "asc") {
                              setOrderBy("desc");
                            }
                          }}
                        >
                          {orderBy === "asc" ? <BiSort /> : <FcAlphabeticalSortingAz />}
                        </OrderButton>
                      </th>
                      <th> </th>

                      {/* <th>Email</th> */}
                      {/* <th>Telefone</th> */}
                      <th>Documentos</th>
                      {/* <th>Ofertas</th> */}
                      <th>Vendedores</th>
                      <th>Cadastro na Via Certa</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <SpinnerContainer>
                            <Spinner animation="border" variant="primary" className="spinner-load-users" />
                          </SpinnerContainer>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {users &&
                        users.map((item) => (
                          <UserRow key={item.cnpj}>
                            <td>
                              <span>
                                <h5>
                                  <StatusCadastro storeId={item.id} />
                                </h5>
                              </span>
                            </td>

                            <td>
                              <span>{item.cnpj}</span>
                            </td>
                            <td>
                              <Button
                                variant="link"
                                onClick={() => {
                                  handleOpenModal(item.id);
                                }}
                              >
                                {item.fantasyName}
                              </Button>
                            </td>
                            <td>
                              <GenericButton onClick={() => handleOpenChat(item.id)}>
                                {item.unreadMessages ? (
                                  <BsChatSquareDotsFill fill="#007bff" />
                                ) : (
                                  <BsChatSquareDots fill="#007bff" />
                                )}
                              </GenericButton>
                            </td>

                            {/* <td>
                            <span>{item.companyEmail}</span>
                          </td>
                          <td>
                            <span className="tag tag-success">
                              <span>{item.companyPhone1}</span>
                            </span>
                          </td> */}
                            <td style={{ cursor: "pointer" }}>
                              <Button onClick={() => handleUserDetail(item.id)}>Documentos</Button>
                            </td>

                            <td style={{ cursor: "pointer" }}>
                              <Button onClick={() => handleUserSalesman(item.id, item.fantasyName)}>Vendedores</Button>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <UserListCardDropDown
                                setUsers={setUsers}
                                setPages={setPages}
                                handleLoading={handleLoading}
                                type={type}
                                userId={item.id}
                              />
                            </td>
                          </UserRow>
                        ))}
                    </tbody>
                  )}
                </table>

                <PaginationComponent
                  numberOfPages={pages}
                  activePage={currentPage}
                  onChangePage={handleChangePage}
                  onFirstPage={handleFirstPage}
                  onLastPage={handleLastPage}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePrevPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

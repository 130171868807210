import { useState, useLayoutEffect } from "react";

export function useMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useLayoutEffect(() => {
    function handler() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  return { isMobile };
}

import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../context/auth";
import { toastTopRightError } from "../../utils/toastConfig";
import { Logo } from "./styles";

export function SignIn() {
  const { handleLogin } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!email || !password) {
      toast("👎 Preencha o Email e a Senha!", toastTopRightError);
      return;
    }

    await handleLogin({ email, password });
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <Logo src="./logo_parcelize.svg" />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Faça o login para iniciar sua sessão</p>

            <form style={{ overflowX: "hidden" }} onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.currentTarget.value);
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.currentTarget.value);
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  margin: 0,
                  padding: 0,
                }}
                className="row"
              >
                <div
                  className="col-4"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    flex: 1,
                    margin: 0,
                  }}
                >
                  <button
                    style={{ width: "100%", maxWidth: "100%" }}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Entrar
                  </button>
                </div>
              </div>
            </form>
            {/* <p style={{ marginTop: "20px" }} className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-0">
              <a href="register.html" className="text-center">
                Register a new membership
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import { SideBar } from "../../../components/Sidebar";
import { UserListCard } from "../../../components/UserListCard";

export function PreRegistration() {
  return (
    <>
      <SideBar />
      <UserListCard type="PRE_CADASTRO" title="Lojas no Pré-Cadastro" />
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";
import { Header } from "./header";
import { useAuthContext } from "../../context/auth";
import { Logo } from "./styles";

export function SideBar() {
  const { user } = useAuthContext();
  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ height: "100%" }}>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <Logo src="/images/icon_parcelize.svg" />
            <div className="info">
              <Link to="/users/registered" className="d-block">
                {user && user.name ? `Bem vindo ${user.name}` : ""}
              </Link>
            </div>
          </div>

          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
              <li className="nav-item">
                <Link
                  to="/retail/registration"
                  className={`nav-link ${pathname === "/retail/registration" ? "active" : ""}`}
                >
                  <i className="nav-icon fa fa-plus" />
                  <p>Cadastro de Franquias</p>
                </Link>
              </li>
              <div className="user-panel mb-3 mt-3" />
              <li className="nav-item">
                <Link to="/users/registered" className={`nav-link ${pathname === "/users/registered" ? "active" : ""}`}>
                  <i className="nav-icon far fas fa-shopping-basket" />
                  <p>Lojas Cadastradas</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/users/no_registered"
                  className={`nav-link ${pathname === "/users/no_registered" ? "active" : ""}`}
                >
                  <i className="nav-icon fas fa-times" />
                  <p>Lojas Não cadastradas</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/users/incomplete_documentation"
                  className={`nav-link ${pathname === "/users/incomplete_documentation" ? "active" : ""}`}
                >
                  <i className="nav-icon far fas fa-address-card" />
                  <p>Lojas Doc. Incompleta</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/users/pre_registration"
                  className={`nav-link ${pathname === "/users/pre_registration" ? "active" : ""}`}
                >
                  <i className="nav-icon far fas fa-ellipsis-h" />
                  <p>Lojas em Pré cadastro</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/retail" className={`nav-link ${pathname === "/retail" ? "active" : ""}`}>
                  <i className="nav-icon far fas fa-shopping-bag" />
                  <p>Franquias</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}

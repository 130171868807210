import { createContext, useContext, useState, useEffect, ReactChild, ReactChildren } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../service/api";
import { toastTopRightError } from "../utils/toastConfig";

enum ROLES {
  USER = "USER",

  ADMIN = "ADMIN",
}

interface Address {
  address: string;
  cep: string;
  city: string;
  complementAddress: string;
  createdAt: string;
  id: string;
  neighborhood: string;
  numberAddress: string;
  referencePointAddress: string;
  updatedAt: string;
}

interface RetailMarket {
  id: string;
  name: string;
  description: string;
  phone: string;
  email: string;
  cnpj: string;
  createdAt: string;
  updatedAt: string;
}

export interface Store {
  address: Address;
  addressId: string;
  cnpj: string;
  companyEmail: string;
  companyPhone1: string;
  companyPhone2: string;
  cpfRespCompany: string;
  cpfRespCompanyParcelize: string;
  createdAt: string;
  emailRespCompany: string;
  emailRespCompanyParcelize: string;
  fantasyName: string;
  files: [];
  id: string;
  ie: string;
  nameRespCompany: string;
  nameRespCompanyParcelize: string;
  phoneRespCompany: string;
  phoneRespCompanyParcelize: string;
  registrationStatus: string;
  retailMarketId: string;
  socialReason: string;
  status: string;
  updatedAt: string;
  salesman: [];
  unreadMessages: [];
  retailMarket: RetailMarket;
  active_status: boolean;
}

export interface User {
  id: string;
  cnpj: string;
  fantasyName: string;
  name: string;
  phone: string;
  email: string;
  username: string;
  role: ROLES;
  documents?: string;
}

interface AuthContextProps {
  user?: User;
  authenticated: boolean;
  handleLogin: (args: { email: string; password: string }) => Promise<void>;
  handleLogout: () => void;
  loading: boolean;
}

interface AuthenticationServerResponse {
  token: string;
  user: User;
}

interface AuthContextProviderProps {
  children: ReactChild | ReactChildren;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

/* eslint-disable  */ // TODO: upgrade to latest eslint tooling


function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('parcelize-admin-token');

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${JSON.parse(token)}`;

      (async () => {
        const response = await api.get<{ user: User }>(`/user`);

        const { user } = response.data;

        setUser(user);
      })();

      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const response = await api.post<AuthenticationServerResponse>(
        '/auth',
        {
          email,
          password,
        },
      );

      if (response.status === 200) {
        const { user, token } = response.data;

        setUser(user);
        localStorage.setItem('parcelize-admin-token', JSON.stringify(token));

        api.defaults.headers.common.Authorization = `Bearer ${token}`;

        setAuthenticated(true);
        history.push('/users');
      }
    } catch (e: any) {
      if (e.response) {
        toast('👎 '+e.response.data.message,toastTopRightError);
      } else {
        toast('👎 Algo de errado aconteceu, tente novamente mais tarde', toastTopRightError);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('parcelize-admin-token');
    api.defaults.headers.common.Authorization = '';
    setAuthenticated(false);
    history.push('/');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated,
        handleLogin,
        handleLogout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuthContext = (): AuthContextProps => useContext(AuthContext);

export { AuthContextProvider, useAuthContext };

import { SalesmanResponse } from "../pages/Salesman";

export function sortSalesmanByCreatedTime(
  usersToSort: SalesmanResponse[],

  order: "asc" | "desc"
) {
  let usersSorted = usersToSort;

  if (order === `asc`) {
    usersSorted = usersToSort.sort((a, b) => {
      const aDate = new Date(a.created_at);

      const bDate = new Date(b.created_at);

      if (aDate < bDate) {
        return -1;
      }

      if (aDate > bDate) {
        return 1;
      }

      return 0;
    });
  }

  if (order === "desc") {
    usersSorted = usersToSort.sort((a, b) => {
      const aDate = new Date(a.created_at);

      const bDate = new Date(b.created_at);

      if (aDate > bDate) {
        return -1;
      }

      if (aDate < bDate) {
        return 1;
      }

      return 0;
    });
  }

  return usersSorted;
}

// import { StaticContext } from 'react-router';
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { useAuthContext } from "../context/auth";
import { SignIn } from "../pages/SignIn";
// import { UsersList } from '../pages/UsersList';

import { UserDetail } from "../pages/UserDetail";

import { PreRegistration } from "../pages/UsersList/PreRegistration";

import { NoRegistered } from "../pages/UsersList/NoRegistered";

import { Registered } from "../pages/UsersList/Registered";

import { IncompleteDocumentation } from "../pages/UsersList/IncompleteDocumentation";

import { Offers } from "../pages/Offers";

import { Salesman } from "../pages/Salesman";

import { RetailRegistration } from "../pages/RetailRegistration";
import { Retails } from "../pages/Retails";

interface CustomRouteProps extends RouteProps {
  isPrivate?: undefined | boolean;
  exact: boolean;
  path: string;
}

function CustomRoute({ isPrivate, ...rest }: CustomRouteProps) {
  const { authenticated, loading } = useAuthContext();
  if (loading) {
    return <h1>Loading</h1>;
  }
  if (isPrivate && !authenticated) {
    return <Redirect to="/" />;
  }
  return <Route {...rest} />;
}

export function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={SignIn} isPrivate={false} />

      <CustomRoute isPrivate exact path="/users" component={Registered} />

      <CustomRoute isPrivate exact path="/user/:userId" component={UserDetail} />

      <CustomRoute isPrivate exact path="/users/pre_registration" component={PreRegistration} />

      <CustomRoute isPrivate exact path="/users/incomplete_documentation" component={IncompleteDocumentation} />

      <CustomRoute isPrivate exact path="/users/no_registered" component={NoRegistered} />

      <CustomRoute isPrivate exact path="/users/registered" component={Registered} />

      <CustomRoute isPrivate exact path="/offers/:userId/:username" component={Offers} />

      <CustomRoute isPrivate exact path="/salesman/:userId/:username" component={Salesman} />

      <CustomRoute isPrivate exact path="/retail/registration" component={RetailRegistration} />

      <CustomRoute isPrivate exact path="/retail" component={Retails} />
    </Switch>
  );
}

import { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import { AuthContextProvider } from "./context/auth";

import { Routes } from "./route/routes";

function App() {
  useEffect(() => {
    document.getElementById("root")?.classList.add("sidebar-mini");

    document.getElementById("root")?.classList.add("layout-fixed");

    return () => {
      document.getElementById("root")?.classList.remove("sidebar-mini");
      document.getElementById("root")?.classList.remove("layout-fixed");
    };
  }, []);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;

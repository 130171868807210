import { Pagination } from "react-bootstrap";

interface IPaginationProps {
  numberOfPages: number;

  activePage?: number;

  onNextPage: () => void;

  onPrevPage: () => void;

  onFirstPage: () => void;

  onLastPage: () => void;

  onChangePage: (page: number) => void;
}

export function PaginationComponent({
  numberOfPages,

  activePage = 1,

  onNextPage,

  onPrevPage,

  onChangePage,

  onLastPage,

  onFirstPage,
}: IPaginationProps) {
  const pages = [];

  for (let index = 0; index < numberOfPages; index += 1) {
    pages.push(index);
  }

  return (
    <Pagination style={{ justifyContent: "center" }}>
      {numberOfPages < 10
        ? pages.map((page) => (
            <Pagination.Item
              key={page}
              active={page === activePage}
              onClick={() => {
                onChangePage(page);
              }}
            >
              {page + 1}
            </Pagination.Item>
          ))
        : pages.map((page) => {
            if (page === 0)
              return (
                <div style={{ display: "flex" }} key={page}>
                  <Pagination.First
                    onClick={() => {
                      onFirstPage();
                    }}
                  />

                  <Pagination.Prev
                    onClick={() => {
                      if (activePage < 1) return;
                      onPrevPage();
                    }}
                  />

                  <Pagination.Item
                    active={page === activePage}
                    onClick={() => {
                      onChangePage(page);
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>

                  <Pagination.Ellipsis />
                </div>
              );

            if (page === numberOfPages - 1)
              return (
                <div style={{ display: "flex" }} key={page}>
                  <Pagination.Ellipsis />

                  <Pagination.Item
                    active={page === activePage}
                    onClick={() => {
                      onChangePage(page);
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={() => {
                      if (activePage >= numberOfPages - 1) return;
                      onNextPage();
                    }}
                  />

                  <Pagination.Last
                    onClick={() => {
                      onLastPage();
                    }}
                  />
                </div>
              );
            if (page >= activePage - 2 && page <= activePage + 2)
              return (
                <Pagination.Item
                  key={page}
                  active={page === activePage}
                  onClick={() => {
                    onChangePage(page);
                  }}
                >
                  {page + 1}
                </Pagination.Item>
              );
            return "";
          })}
    </Pagination>
  );
}

export function useSideBarHandler() {
  function toggleSideBar() {
    const row = document.querySelector(".container-page ");

    if (row) {
      row.classList.toggle("page-margin-left");
    }
  }

  return { toggleSideBar };
}

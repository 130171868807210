import styled from "styled-components";

export const ContainerForm = styled.main`
  margin-top: 50px;

  h2 {
    margin-bottom: 50px;
  }

  h1 {
    margin-bottom: 50px;
  }

  padding: 0 20px;

  button {
    margin-top: 50px;

    padding: 5px 10px;
  }

  .list-group-item {
    cursor: pointer;
  }
`;

export const UserRow = styled.tr`
  td > span {
    display: inline-block;

    white-space: nowrap;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;

    border: 1px solid transparent;

    padding: 0.375rem 0;

    font-size: 1rem;

    line-height: 1.5;
  }

  .badge {
    vertical-align: middle;

    display: inline-block;

    text-align: center;

    white-space: nowrap;

    vertical-align: middle;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;

    border: 1px solid transparent;

    margin: 0.1rem 0.75rem;

    line-height: 1.5;
  }
`;

export const OrderButton = styled.button`
  margin-left: 10px;
  width: 30px;
  background-color: transparent;
  border: none;
`;

export const GenericButton = styled.button`
  width: 100%;
  height: 40px;
  border: none;

  padding: 0;
  background-color: transparent;

  svg {
    width: 25px;
    height: 25px;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

import { useEffect, useState /* , useMemo, useCallback */ } from "react";
import Badge from "react-bootstrap/Badge";
import { toast } from "react-toastify";
import { api } from "../../service/api";
import { toastTopRightError } from "../../utils/toastConfig";

interface Document {
  id: string;
  originalName: string;
  size: number;
  localName: string;
  url: string;
  type: string;
  section: string;
  offerId: string;
  storeId: string;
}

interface StatusCadastroProps {
  storeId: string;
}

export function StatusCadastro({ storeId }: StatusCadastroProps) {
  const [documents, setDocuments] = useState<Document[]>([]);
  let completed = false;

  const findedDocument = documents.find((item) => item.section === "formulario cadastro");

  if (findedDocument) {
    completed = true;
  } else {
    completed = false;
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<{ files: Document[] }>(`/files?storeId=${storeId}`);
        setDocuments(response.data.files);
      } catch (e) {
        toast("👎 Erro ao obter os documentos.", toastTopRightError);
      }
    })();
  }, [storeId]);

  return completed ? (
    <Badge pill bg="success" text="light">
      Sim
    </Badge>
  ) : (
    <Badge pill bg="danger">
      Não
    </Badge>
  );
}

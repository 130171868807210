import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSideBarHandler } from "../../context/useSideBarHandler";
import { useAuthContext } from "../../context/auth";

/* eslint-disable jsx-a11y/anchor-is-valid */
export function Header() {
  const { handleLogout } = useAuthContext();
  const { toggleSideBar } = useSideBarHandler();

  return (
    <nav
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      className="main-header navbar navbar-expand navbar-white navbar-light"
    >
      <ul className="navbar-nav">
        <li className="nav-item" onClick={toggleSideBar}>
          <a className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/users" className="nav-link">
            Usuários
          </Link>
        </li>
      </ul>

      <ul className="navbar-nav">
        <li className="nav-item">
          <Button style={{ marginLeft: "15px", alignSelf: "flex-end" }} onClick={handleLogout}>
            Logout
          </Button>
        </li>
      </ul>
    </nav>
  );
}

import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Store } from "../../context/auth";
import { api } from "../../service/api";
import { toastTopRightError } from "../../utils/toastConfig";
import { IStoresResponse } from "../UserListCard";

interface UserListCardDropDown {
  setUsers: React.Dispatch<React.SetStateAction<Store[]>>;
  setPages: React.Dispatch<React.SetStateAction<number>>;
  userId: string;
  handleLoading: (status: boolean) => void;
  type: "ESPERANDO_FINANCEIRA" | "PENDENTE" | "COMPLETO" | "PRE_CADASTRO";
}

export function UserListCardDropDown({ userId, handleLoading, setUsers, setPages, type }: UserListCardDropDown) {
  async function handleChangeStatus(storeId: string, status: string) {
    try {
      // change user status
      handleLoading(true);
      await api.patch<void>("/store/update-status", {
        storeId,
        status,
      });
      // update user list
      const response = await api.get<IStoresResponse>(`store/status/${type}`);
      const storeResponse = response.data.stores;

      setUsers(storeResponse.data);
      setPages(Math.ceil(storeResponse.total / storeResponse.per_page));
      handleLoading(false);
    } catch (e) {
      toast("👎 Algo deu errado ao pegar usuarios", toastTopRightError);
      handleLoading(false);
    } finally {
      handleLoading(false);
    }
  }

  const variant = {
    ESPERANDO_FINANCEIRA: "warning",
    COMPLETO: "success",
    PENDENTE: "secondary",
    PRE_CADASTRO: "danger",
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant[type]} id="dropdown-basic">
        {type === "COMPLETO" && "Cadastrado"}
        {type === "PENDENTE" && "Documentação Incompleta"}
        {type === "ESPERANDO_FINANCEIRA" && "Não Cadastrado"}
        {type === "PRE_CADASTRO" && "Pré Cadastro"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {type === "COMPLETO" && (
          <>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "ESPERANDO_FINANCEIRA");
              }}
            >
              Não Cadastrado
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "PENDENTE");
              }}
            >
              Documentação Incompleta
            </Dropdown.Item>
          </>
        )}
        {type === "PENDENTE" && (
          <>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "ESPERANDO_FINANCEIRA");
              }}
            >
              Não Cadastrado
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "COMPLETO");
              }}
            >
              Cadastrado
            </Dropdown.Item>
          </>
        )}
        {type === "ESPERANDO_FINANCEIRA" && (
          <>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "PENDENTE");
              }}
            >
              Documentação Incompleta
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleChangeStatus(userId, "COMPLETO");
              }}
            >
              Cadastrado
            </Dropdown.Item>
          </>
        )}
        {type === "PRE_CADASTRO" && <Dropdown.Item disabled>Inativar Cadastro</Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  );
}

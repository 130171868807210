import { SideBar } from "../../../components/Sidebar";
import { UserListCard } from "../../../components/UserListCard";

export function Registered() {
  return (
    <>
      <SideBar />
      <UserListCard type="COMPLETO" title="Lojas com Cadastro Completo" />
    </>
  );
}

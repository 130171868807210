import { FormEvent, useEffect, useRef, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Store, useAuthContext } from "../../context/auth";
import { api } from "../../service/api";

interface ChatModalProps extends ModalProps {
  show: boolean;
  storeId: string;
}

interface Sender {
  createdAt: string;
  email: string;
  id: string;
  name: string;
  password: string;
  phone: string;
  role: "ADMIN" | "USER" | "FINANCEIRA";
  updatedAt: string;
}

interface Message {
  created_at: string;
  id: string;
  message: string;
  message_was_read: boolean;
  sender: Sender;
  sender_type: "ADMIN" | "USER";
  updated_at: string;
}

export function ChatModal({ show, storeId, onHide, ...props }: ChatModalProps) {
  // const data = new Date().toLocaleDateString('pt-BR');
  const { user } = useAuthContext();
  const [messages, setMessages] = useState<Message[] | null>([]);
  const [messageToSend, setMessageToSend] = useState("");
  const [store, setStore] = useState<Store | null>();
  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function getMessages() {
      const getMessagesResponse = await api.get<{ messages: Message[] }>(`/messages?storeId=${storeId}`);
      const getStoreResponse = await api.get<{ store: Store }>(`/store/${storeId}`);
      const notReadMessages = getMessagesResponse.data.messages.filter(
        (message) => message.message_was_read === false && message.sender.role === "USER"
      );

      if (notReadMessages.length) {
        await api.put("/messages/read", {
          storeId,
          messageIds: notReadMessages.map((message) => message.id),
        });
      }

      setMessages(getMessagesResponse.data.messages);
      setStore(getStoreResponse.data.store);
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }
    if (storeId && messagesRef.current && show === true) {
      setStore(null);
      setMessages(null);
      getMessages();
    }
  }, [storeId, show]);

  async function sendMessage(e: FormEvent) {
    e.preventDefault();

    if (!messageToSend.trim().length) {
      return;
    }
    const response = await api.post<{ message: string; id: string }>(`/messages`, {
      message: messageToSend,
      storeId,
    });
    if (response.status === 201) {
      const response2 = await api.get<{ messages: Message[] }>(`/messages?storeId=${storeId}`);
      setMessages(response2.data.messages);
      setMessageToSend("");
    }
    if (messagesRef.current) {
      messagesRef.current.scrollBy({
        behavior: "smooth",
        top: messagesRef.current.scrollHeight,
      });
    }
  }

  return (
    <Modal show={show} centered size="lg" dialogClassName="chat-modal" onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{store && store?.fantasyName.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <div className="container">
          <div className="col-12 px-0 overflow">
            <div className="px-4 py-5" ref={messagesRef} style={{ overflow: "auto", height: "500px" }}>
              {messages &&
                messages?.length > 0 &&
                messages?.map((message) => {
                  if (message.sender_type === "ADMIN") {
                    return (
                      <div className="media w-50 ml-auto mb-3" key={message.id}>
                        <div className="media-body">
                          <div className="bg-primary rounded py-2 px-3 mb-2">
                            <span>
                              <b>
                                {user?.id === message.sender.id ? "Você" : message?.sender?.name?.toLocaleLowerCase()}
                              </b>
                            </span>
                            <p className="text-small mb-0 text-white">{message.message}</p>
                          </div>
                          <p className="small text-muted text-right">
                            <b>{new Date(message.created_at).toString().slice(15, 21)}</b>{" "}
                            {`${new Date(message.created_at).getDate().toString()}/${new Date(message.created_at)
                              .getMonth()
                              .toString()}/${new Date(message.created_at).getFullYear().toString()}`}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className="media w-50 mb-3" key={message.id}>
                      <div className="media-body ml-3">
                        <div className="bg-light rounded py-2 px-3 mb-2">
                          <span className="text-muted">
                            <b>{message?.sender?.name?.toLocaleLowerCase() || "Usuário sem nome"}:</b>
                          </span>
                          <p className="text-small mb-0 text-muted">{message.message}</p>
                        </div>
                        <p className="small text-muted text-left">
                          <b>{message.created_at.slice(11, 16)}</b>{" "}
                          {message.created_at.slice(0, 10).split("-").reverse().join("/")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Form action="#" className="bg-light form-chat-modal" onSubmit={sendMessage}>
              <div>
                <Form.Group className="mb-3 input-div" controlId="chatMessage">
                  <Form.Control
                    as="textarea"
                    value={messageToSend}
                    onChange={(e) => setMessageToSend(e.target.value)}
                    placeholder="Envie uma mensagem"
                  />
                </Form.Group>
                <button id="button-addon2" type="submit" className="btn btn-link">
                  <i className="fa fa-paper-plane" />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { useEffect, useMemo, useState } from "react";

import { Button } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { api } from "../../service/api";

import { SideBar } from "../../components/Sidebar";
import { useSideBarHandler } from "../../context/useSideBarHandler";
import { useMobile } from "../../context/windowSize";
import { toastTopRightError } from "../../utils/toastConfig";

interface Document {
  id: string;
  localName: string;
  offerId: null | string;
  originalName: string;
  section: string;
  size: number;
  type: string;
  url: string;
  userId: string;
}

interface DefaultDocuments extends Partial<Document> {
  title: string;
  isUploaded: boolean;
  type: string;
  section: string;
}

export function UserDetail() {
  const { userId } = useParams<{ userId: string }>();
  const [documents, setDocuments] = useState<Document[]>([]);
  const history = useHistory();

  const { toggleSideBar } = useSideBarHandler();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<{ files: Document[] }>(`/files?storeId=${userId}`);

        setDocuments(response.data.files);
      } catch (e) {
        toast("👎 Algo deu errado ao pegar o usuário", toastTopRightError);
      }
    })();
  }, [userId]);

  const defaultFiles: DefaultDocuments[] = useMemo(() => {
    const files = [
      {
        title: "Contrato Social",
        isUploaded: false,
        type: "document",
        section: "contrato social",
      },
      {
        title: "Cartão CNPJ",
        isUploaded: false,
        type: "document",
        section: "cartao cnpj",
      },
      {
        title: "CPF e RG do sócio (1)",
        isUploaded: false,
        type: "document",
        section: "cpf e rg dos socios (1)",
      },
      {
        title: "CPF e RG do sócio (2)",
        isUploaded: false,
        type: "document",
        section: "cpf e rg dos socios (2)",
      },
      {
        title: "CPF e RG do sócio (3)",
        isUploaded: false,
        type: "document",
        section: "cpf e rg dos socios (3)",
      },
      {
        title: "Dados conta bancária",
        isUploaded: false,
        type: "document",
        section: "conta bancaria",
      },
      {
        title: "Alvará de funcionamento",
        isUploaded: false,
        type: "document",
        section: "alvara de funcionamento",
      },
      {
        title: "Foto da fachada da loja",
        isUploaded: false,
        type: "document",
        section: "fachada",
      },
      {
        title: "Fotos da área interna da loja",
        isUploaded: false,
        type: "document",
        section: "area interna",
      },
      {
        title: "Logotipo*",
        isUploaded: false,
        type: "document",
        section: "logotipo",
      },
      {
        title: "Formulário de cadastro assinado",
        isUploaded: false,
        type: "form",
        section: "formulario cadastro",
      },
    ];

    return files;
  }, []);

  const files = useMemo(() => {
    const formatted = defaultFiles.map((defaultFile) => {
      const findedDocument = documents.find((item) => item.section === defaultFile.section);

      if (findedDocument) {
        return {
          isUploaded: true,
          title: defaultFile.title,
          ...findedDocument,
        };
      }

      return {
        ...defaultFile,
        isUploaded: false,
      };
    });

    return formatted;
  }, [documents, defaultFiles]);

  const { isMobile } = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SideBar />
      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "20px" }} className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Documentos da Loja {userId}</h3>
              </div>
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Documento</th>
                      <th>Nome do arquivo</th>
                      <th>Disponibilidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((item) =>
                      (item.title === "CPF e RG do sócio (2)" && !item.isUploaded) ||
                      (item.title === "CPF e RG do sócio (3)" && !item.isUploaded) ? null : (
                        <tr style={{ cursor: "pointer" }} key={item.section}>
                          <td>{item.title}</td>
                          <td>{item.isUploaded ? item.originalName : "-"}</td>
                          <td>
                            {!item.isUploaded && "Pendente"}
                            {item.isUploaded && (
                              <Button
                                variant="primary"
                                className="btn-download"
                                disabled={!item.isUploaded}
                                onClick={() => {
                                  saveAs(item.url!, item.originalName);
                                }}
                              >
                                Download
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Button
            style={{ marginLeft: "15px" }}
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
        </div>
      </div>
      <div id="sidebar-overlay" role="presentation" data-widget="pushmenu" onClick={toggleSideBar} />
    </>
  );
}

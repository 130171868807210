import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { BiSort } from "react-icons/bi";
import { FcAlphabeticalSortingAz } from "react-icons/fc";
import { toast } from "react-toastify";
import { PaginationComponent } from "../../components/Pagination";
import { SideBar } from "../../components/Sidebar";
import { OrderButton } from "../../components/UserListCard/styles";
import { useMobile } from "../../context/windowSize";
import { api } from "../../service/api";

type RetailMarket = {
  cnpj: string;
  createdAt: string;
  description: string;
  email: string;
  id: string;
  name: string;
  phone: string;
  showInSearch: boolean;
  strAtendente: string | null;
  strCodigoEstabelecimento: string | null;
  strLojista: string | null;
  strOrigem: string | null;
  strPontoAtendimento: string | null;
  strProduto: string | null;
  updatedAt: string | null;
};

interface IGetDataResponse {
  current_page: number;
  per_page: number;
  total: number;
  data: RetailMarket[];
}

export function Retails() {
  const { isMobile } = useMobile();
  const [page, setPage] = useState(1);
  const [retails, setRetails] = useState<RetailMarket[] | null>([]);
  const [sort, setSort] = useState<"desc" | "asc" | null>(null);
  const [total, setTotal] = useState(0);
  const limit = 10;
  const numberOfPages = total ? Math.ceil(total / limit) : 0;

  const controller = new AbortController();

  useEffect(() => {
    async function getData() {
      const response = await api.get<{ retailMarket: IGetDataResponse }>(`/retail-market`, {
        signal: controller.signal,
        params: {
          page,
          limit,
          orderBy: sort,
          showInSearch: true,
        },
      });

      setRetails(response.data.retailMarket.data);
      setTotal(response.data.retailMarket.total);
    }
    getData();

    return () => controller.abort();
  }, [page, sort]);

  function onNextPage() {
    setPage(page + 1);
  }
  function onPrevPage() {
    setPage(page - 1);
  }

  function onChangePage(p: number) {
    setPage(p + 1);
  }

  function onLastPage() {
    setPage(numberOfPages);
  }

  function onFirstPage() {
    setPage(1);
  }

  function copyText(link: string) {
    try {
      navigator.clipboard.writeText(link);
      toast.success(`Texto copiado com sucesso!`);
    } catch (e) {
      toast.error("Falha ao copiar texto");
    }
  }

  return (
    <>
      <SideBar />
      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <Card style={{ margin: "20px", width: "100vw" }}>
          <Card.Header>
            <Card.Title>Franquias Cadastradas</Card.Title>
          </Card.Header>

          <Table bordered>
            <thead>
              <tr>
                <th>Id</th>
                <th>
                  Nome{" "}
                  <OrderButton
                    onClick={() => {
                      if (sort === null || sort === "desc") {
                        setSort("asc");
                      } else {
                        setSort("desc");
                      }
                    }}
                  >
                    {sort === null || sort === "desc" ? <BiSort /> : <FcAlphabeticalSortingAz />}
                  </OrderButton>
                </th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Cnpj</th>
                <th>Descrição</th>
                <th>Link P/ Cadastro de Loja</th>
              </tr>
            </thead>
            <tbody>
              {retails &&
                retails.map((retail) => (
                  <tr key={retail.id}>
                    <td>{retail.id}</td>
                    <td>{retail.name}</td>
                    <td>{retail.phone}</td>
                    <td>{retail.email}</td>
                    <td>{retail.cnpj}</td>
                    <td>{retail.description}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => copyText(`https://parcelize.com.br/register_shopkeeper?id=${retail.id}`)}
                      >
                        Copiar Link
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {numberOfPages && (
            <PaginationComponent
              numberOfPages={numberOfPages}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              activePage={page - 1}
              onChangePage={onChangePage}
              onLastPage={onLastPage}
              onFirstPage={onFirstPage}
            />
          )}
          <Card.Body />
        </Card>
      </div>
    </>
  );
}

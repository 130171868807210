import { Button, Modal } from "react-bootstrap";

interface ModalDeleteOfferProps {
  show: boolean;

  handleCloseModal: () => void;

  handleConfirmAction: () => void;

  bodyMessage: string;

  title: string;
}

export function ModalConfirmAction({
  show,

  handleCloseModal,

  handleConfirmAction,

  bodyMessage,

  title,
}: ModalDeleteOfferProps) {
  return (
    <Modal show={show} centered>
      <Modal.Header>{title}</Modal.Header>

      <Modal.Body>{bodyMessage}</Modal.Body>

      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={() => handleCloseModal()}>
          Fechar
        </Button>

        <Button className="btn btn-primary" onClick={() => handleConfirmAction()}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

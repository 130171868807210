import { SideBar } from "../../../components/Sidebar";
import { UserListCard } from "../../../components/UserListCard";

// import { Console } from "console";

export function NoRegistered() {
  return (
    <>
      <SideBar />
      <UserListCard type="ESPERANDO_FINANCEIRA" title="Lojas Ainda Não Cadastradas na Viacerta" />
    </>
  );
}

import { FormEvent, useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import MaskedFormControl from "../../components/MaskedFormControl";
import { SideBar } from "../../components/Sidebar";
import { useMobile } from "../../context/windowSize";
import { api } from "../../service/api";

export function RetailRegistration() {
  const { isMobile } = useMobile();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await api.post("/retail-market", { name, phone, email, cnpj, description });

      if (response.status === 201) {
        setName("");
        setPhone("");
        setEmail("");
        setCnpj("");
        setDescription("");
      }

      toast.success("Varejo Cadastrado com Sucesso", { position: "bottom-right" });
    } catch (err: any) {
      toast.error(err.response?.data?.message || "Ocorreu algum erro, tente novamente mais tarde.", {
        position: "bottom-right",
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <SideBar />
      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <Card style={{ margin: "20px" }}>
          <Card.Header>
            <Card.Title>Cadastre uma Franquia</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text />

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3 mt-3" controlId="nome">
                <Form.Label>Nome da Franquia</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Digite o nome da Franquia"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 mt-3" controlId="telefone">
                <Form.Label>Telefone</Form.Label>
                {
                  // eslint-disable-next-line
                  // @ts-ignore
                }
                <MaskedFormControl
                  required
                  mask="(11)11111-1111"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  placeholder="Digite o email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 mt-3" controlId="cnpj">
                <Form.Label>Cnpj</Form.Label>
                <MaskedFormControl
                  required
                  mask="11.111.111/1111-11"
                  value={cnpj}
                  onChange={(e) => {
                    setCnpj(e.target.value);
                  }}
                />
                {/* <Form.Text className="text-muted">Well never share your email with anyone else.</Form.Text> */}
              </Form.Group>

              <Form.Group className="mb-3 mt-3" controlId="descricao">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  value={description}
                  placeholder="Digite a descrição"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {/* <Form.Text className="text-muted">Well never share your email with anyone else.</Form.Text> */}
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isLoading}>
                Cadastrar
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

import React from "react";
import MaskedInput, { MaskedInputProps } from "react-maskedinput";
import { FormControl } from "react-bootstrap";

export default function MaskedFormControl(props: MaskedInputProps) {
  // eslint-disable-next-line
    // @ts-ignore

  return <FormControl as={MaskedInput} {...props} />;
}

import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { toast } from "react-toastify";
import { SideBar } from "../../components/Sidebar";
import { useSideBarHandler } from "../../context/useSideBarHandler";
import { useMobile } from "../../context/windowSize";
import { api } from "../../service/api";
import { toastTopRightError } from "../../utils/toastConfig";
import { sortSalesmanByCreatedTime } from "../../utils/sortSalesmanByCreatedTime";

// import { Form } from "react-bootstrap";

export interface SalesmanResponse {
  id: string;
  name: string;
  phone: string;
  email: string;
  cpf: string;
  username: string;
  password: string;
  status: boolean;
  file: {
    id: string;
    originalName: string;
    url: string;
  };
  created_at: string;
  updated_at: string;
}

export function Salesman() {
  const { userId, username } = useParams<{
    userId: string;

    username: string;
  }>();
  const [salesman, setSalesman] = useState<SalesmanResponse[]>([]);
  const [salesmanToShow, setSalesmanToShow] = useState<SalesmanResponse[]>([]);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("desc");
  const [orderByCreationTime, setOrderByCreationTime] = useState(false);
  const history = useHistory();
  const { toggleSideBar } = useSideBarHandler();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<SalesmanResponse[]>(`/salesman/${userId}`, {
          params: {
            orderByStatus: orderBy,
          },
        });

        if (response.status === 200) {
          setSalesman([...response.data]);
          setSalesmanToShow([...response.data]);
        }
      } catch (e) {
        toast("👎 Algo deu errado ao pegar o usuário", toastTopRightError);
      }
    })();
  }, [userId, orderBy]);

  const { isMobile } = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpdateSalesmanState = async (
    status: boolean,

    salesmanId: string
  ) => {
    try {
      const response = await api.put(`/salesman/${salesmanId}`, {
        status,
      });

      if (response.status !== 200) {
        return toast(
          "👎 Algo de errado ao atualizar status de vendedor, tente novamente mais tarde!",
          toastTopRightError
        );
      }

      const res = await api.get<{ salesman: SalesmanResponse[] }>(`/salesman/${userId}`, {
        params: {
          orderByStatus: orderBy,
        },
      });

      if (res.status === 200) {
        setSalesman([...res.data.salesman]);
        setSalesmanToShow([...res.data.salesman]);
      }
    } catch (e) {
      toast("👎 Algo deu errado ao pegar o usuário", toastTopRightError);
    }

    return null;
  };

  const handleOrderBy = () => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");
  };

  function handleOrderByCreationTime() {
    if (orderByCreationTime === true) {
      setOrderByCreationTime(!orderByCreationTime);
      setSalesmanToShow(sortSalesmanByCreatedTime(salesman, "asc"));
    } else {
      setOrderByCreationTime(!orderByCreationTime);
      setSalesmanToShow(sortSalesmanByCreatedTime(salesman, "desc"));
    }
  }

  return (
    <>
      <SideBar />

      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <div
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "20px",
          }}
          className="row"
        >
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  Vendedores de <b>{username}</b>
                </h3>
              </div>

              <div className="card-body table-responsive p-0">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th style={{ width: "10px" }}>#</th>

                      <th>Nome</th>

                      <th>Email</th>

                      <th>CPF</th>

                      <th>Número de telefone</th>

                      <th>Usuário</th>

                      <th>Senha</th>

                      <th style={{ cursor: "pointer" }} onClick={handleOrderBy}>
                        Status
                        {orderBy === "desc" ? <BsArrowDownShort size={30} /> : <BsArrowUpShort size={30} />}
                      </th>
                      <th> </th>
                      <th>
                        Criado em{" "}
                        {orderByCreationTime === true ? (
                          <BsArrowDownShort
                            style={{ cursor: "pointer" }}
                            size={30}
                            onClick={() => handleOrderByCreationTime()}
                          />
                        ) : (
                          <BsArrowUpShort
                            style={{ cursor: "pointer" }}
                            size={30}
                            onClick={() => handleOrderByCreationTime()}
                          />
                        )}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {salesmanToShow.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}.</td>

                        <td>{item.name}</td>

                        <td>{item.email}</td>

                        <td>{item.cpf}</td>

                        <td>{item.phone}</td>

                        <td>{item.username}</td>

                        <td>{item.password}</td>

                        <td
                        // style={{
                        //   color: item.status ? '#28a745' : '#dc3545',
                        // }}
                        >
                          {item.status ? (
                            <Badge bg="success">Cadastrado</Badge>
                          ) : (
                            <Badge bg="warning">Não Cadastrado</Badge>
                          )}
                        </td>
                        <td>
                          <Button
                            style={{ width: "200px" }}
                            variant={!item.status ? "success" : "danger"}
                            className="btn-download"
                            onClick={() => handleUpdateSalesmanState(!item.status, item.id)}
                          >
                            {item.status ? "Remover cadastro" : "Confirmar Cadastro"}
                          </Button>
                        </td>
                        <td>{item.created_at.substring(0, 10).split("-").reverse().join("/")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Button
            style={{ marginLeft: "15px", width: "200px" }}
            onClick={() => {
              history.push("/users");
            }}
          >
            Voltar
          </Button>
        </div>
      </div>

      <div id="sidebar-overlay" role="presentation" data-widget="pushmenu" onClick={toggleSideBar} />
    </>
  );
}

import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
`;

export default createGlobalStyle`
 * {
   padding: 0;
   margin: 0;
   /* overflow-y: hidden !important; */
  }

  html, body, div#root {
    height: 100%;
    width: 100%;
  }

  body:not(.layout-fixed) .main-sidebar {
    width: fit-content;
    min-width: 250px;
    height: 100%;
    position: fixed;
  }

  .btn.btn-link {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }

  .modal-info {
    min-width: 60vw;
  }

  .chat-modal {
    min-width: 30vw;
    padding: 0;
  }

  .form-chat-modal {
    > div {
      display: flex;
    }

    .input-div {
      flex:12;
      margin-top: 1rem;
      padding-left: 1rem;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex:1;
    }
  }

  .spinner-load-modal {
    margin: 40vh auto;
    width: 200px;
    height: 200px;
  }

  .spinner-load-users {
    width: 100px;
    height: 100px;
    margin: 100px 0;
  }

  .container-page {
    transition: margin-left .3s;
  }

  .page-margin-left {
    margin-left: 250px;
  }

  .table:not(.table-dark) {
    .icon-delete:hover {
      color: #dc3545;
    }

    .icon-update:hover {
      color: #007bff;
    }
  }

  .card-body::after, .card-footer::after, .card-header::after {
    display: none;
    clear: both;
    content: "";
  }
`;

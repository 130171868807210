import { Modal, Button } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Files, OffersProps } from "../../pages/Offers";
import { api } from "../../service/api";
import { toastTopRightError, toastTopRightSuccess } from "../../utils/toastConfig";

export interface FileWithIsUploaded extends Partial<Files> {
  isUploaded: boolean;
}

interface ModalEditOfferProps {
  show: boolean;
  handleCloseModal: () => void;
  title: string;
  description: string;
  id: string;
  files: Files[];
  userId: string;
  price: string;
  updateOffersState: (offer: OffersProps) => void;
}

export function ModalEditOffer({
  id,
  handleCloseModal,
  show,
  title: defaultTitle,
  description: defaultDescription,
  files: defaultFiles = [],
  userId,
  price: defaultPrice,
  updateOffersState,
}: ModalEditOfferProps) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [offerId, setOfferId] = useState("");
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    if (defaultTitle || defaultDescription || id || defaultPrice) {
      setTitle(defaultTitle);
      setDescription(defaultDescription);
      setOfferId(id);
      setPrice(defaultPrice);
    }
  }, [defaultTitle, defaultDescription, id, defaultPrice]);

  useEffect(() => {
    if (defaultFiles.length > 0) {
      setFiles(defaultFiles);
    }
  }, [defaultFiles]);

  const filesFormatted: FileWithIsUploaded[] = useMemo(() => {
    const arr = [...Array(3).fill("")];
    const mapped = arr.map((_, index) => {
      if (files[index]) {
        return {
          ...files[index],
          isUploaded: true,
        };
      }
      return {
        isUploaded: false,
      };
    });

    return mapped;
  }, [files]);

  const handleDelete = async (fileId?: string) => {
    if (!fileId) return;

    try {
      const response = await api.delete(`/documents/${fileId}/${userId}`);

      if (response.status === 200) {
        toast("👍 Excluído com sucesso", toastTopRightSuccess);
        const indexOf = files.findIndex((item) => item.id === fileId);

        if (indexOf !== -1) {
          const newArr = [...files];
          newArr.splice(indexOf, 1);

          setFiles(newArr);
        }
      }
    } catch (e) {
      toast(`👎 Arquivo não pode ser deletado`, toastTopRightError);
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const formData = new FormData();

      const file = event.currentTarget.files ? event.currentTarget.files[0] : undefined;

      if (file) {
        formData.append("file", file, file.name);
      }

      const response = await api.post<Files>(`/documents/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          type: "offer",
          section: "offer",
          offerId,
        },
      });

      if (response.status === 200) {
        const newFile = {
          id: response.data.id,
          offerId,
          originalName: response.data.originalName,
          url: response.data.url,
          userId,
        };

        setFiles((oldFiles) => [...oldFiles, newFile]);
        toast(`👍 Arquivo enviado com sucesso`, toastTopRightSuccess);

        return {};
      }
    } catch (e) {
      toast(`👎 O arquivo não pode ser enviado`, toastTopRightError);
    }

    return null;
  };

  const handleUpdateOffer = async () => {
    try {
      const response = await api.patch("/offer", {
        offer: {
          title,
          description,
          price,
          id: offerId,
        },
        userId,
      });

      if (response.status === 200) {
        toast("👍 Oferta editada com sucesso!", toastTopRightSuccess);
        updateOffersState({
          id,
          title,
          description,
          price,
          userId,
          files,
        });
        handleCloseModal();
      } else {
        toast("👎Algo deu errado na edição da oferta", toastTopRightError);
      }
    } catch (e) {
      toast("👎Algo deu errado na alteração da oferta!", toastTopRightError);
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>Editar oferta #{id}</Modal.Header>
      <Modal.Body>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="input-title">
                Titulo
                <input
                  type="text"
                  className="form-control"
                  id="input-title"
                  value={title}
                  onChange={(event) => setTitle(event.currentTarget.value)}
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="input-description">
                Descrição
                <input
                  type="text"
                  className="form-control"
                  id="input-description"
                  value={description}
                  onChange={(event) => setDescription(event.currentTarget.value)}
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="input-price">
                Preço
                <input
                  type="text"
                  className="form-control"
                  id="input-price"
                  value={price}
                  onChange={(event) => setPrice(event.currentTarget.value)}
                />
              </label>
            </div>
            <div className="form-group">
              {filesFormatted.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      marginTop: index === 0 ? "" : "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    className="input-group"
                  >
                    <div className="custom-file">
                      <input
                        disabled={item.isUploaded}
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        onChange={handleUpload}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {item.isUploaded ? item.originalName : ""}
                      </label>
                    </div>
                    {item.isUploaded && (
                      // eslint-disable-next-line
                      <i
                        onClick={() => handleDelete(item.id)}
                        style={{ cursor: "pointer" }}
                        className="fas fa-trash-alt icon-delete"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={() => handleCloseModal()}>
          Fechar
        </Button>
        <Button className="btn btn-primary" onClick={() => handleUpdateOffer()}>
          Editar Oferta
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

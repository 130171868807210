import styled from "styled-components";

export const Input = styled.textarea`
  width: 100%;

  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }

  width: 100%;
`;

import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SideBar } from "../../components/Sidebar";
import { useSideBarHandler } from "../../context/useSideBarHandler";
import { useMobile } from "../../context/windowSize";
import { api } from "../../service/api";
import { ModalConfirmAction } from "../../components/ModalConfirmAction";
import { ModalEditOffer } from "../../components/ModalEditOffer";
import { ModalCreateOffer } from "../../components/ModalCreateOffer";
import { toastTopRightError, toastTopRightSuccess } from "../../utils/toastConfig";

export interface Files {
  id: string;

  offerId: string;

  originalName: string;

  url: string;

  userId: string;
}

export interface OffersProps {
  id: string;

  title: string;

  description: string;

  price: string;

  userId: string;

  files: Files[];
}

export function Offers() {
  const { userId, username } = useParams<{
    userId: string;

    username: string;
  }>();
  const [offers, setOffers] = useState<OffersProps[]>([]);
  const [showDeleteOfferModal, setShowDeleteOfferModal] = useState(false);
  const [offerId, setOfferId] = useState<string>("");
  const [showEditOfferModal, setShowEditModalOffer] = useState(false);
  const [showCreateOfferModal, setShowCreateOfferModal] = useState(false);
  const [offerToEdit, setOfferToEdit] = useState<OffersProps>({} as OffersProps);
  const history = useHistory();

  const { toggleSideBar } = useSideBarHandler();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<OffersProps[]>(`/offers/${userId}`);

        if (response.status === 200) {
          setOffers([...response.data]);
        }
      } catch (e) {
        toast("👎 Algo deu errado ao pegar o usuário", toastTopRightError);
      }
    })();
  }, [userId]);

  const { isMobile } = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteOffer = async () => {
    try {
      const response = await api.delete(`/offer/${offerId}/${userId}`);

      if (response.status === 200) {
        const offersWithoutDeleted = offers.filter((item) => item.id !== offerId);

        setOffers(offersWithoutDeleted);

        setShowDeleteOfferModal(false);

        setTimeout(() => {
          toast("👍 Oferta deletada com sucesso", toastTopRightSuccess);
        }, 100);
      }
    } catch (e) {
      toast("Algo deu errado ao deletar oferta, tente novamente mais tarde!", toastTopRightError);
    }
  };

  const updateOffersState = (updatedOffer: OffersProps) => {
    const offersWithoutUpdated = offers.filter((item) => item.id !== updatedOffer.id);

    const indexOfUpdatedOffer = offers.findIndex((item) => item.id === updatedOffer.id);

    if (indexOfUpdatedOffer !== -1) {
      const newArr = offersWithoutUpdated;

      newArr.splice(indexOfUpdatedOffer, 1, updatedOffer);

      setOffers([...newArr]);
    }
  };

  const addOfferState = (newOffer: OffersProps) => {
    setOffers([...offers, newOffer]);
  };

  return (
    <>
      <SideBar />

      <div className={`container-page ${isMobile ? "" : "page-margin-left"}`}>
        <div
          style={{
            marginLeft: "0px",

            marginRight: "0px",

            marginTop: "20px",

            display: "flex",

            flexDirection: "column",
          }}
          className="row"
        >
          <div className="col-md-6" style={{ width: "100%", flex: 1, maxWidth: "100%" }}>
            <div className="card">
              <div
                style={{
                  width: "100%",

                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",
                }}
                className="card-header"
              >
                <h3 className="card-title">
                  Ofertas de <b>{username}</b>
                </h3>

                <Button onClick={() => setShowCreateOfferModal(!showCreateOfferModal)}>Criar Oferta</Button>
              </div>

              <div className="card-body p-0">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "10px" }}>#</th>

                      <th>Titulo</th>

                      <th>Descrição</th>

                      <th>Preço</th>

                      <th>Número de arquivos</th>
                    </tr>
                  </thead>

                  <tbody>
                    {offers.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}.</td>

                        <td>{item.title}</td>

                        <td>{item.description}</td>

                        <td>{item.price}</td>

                        <td>{item.files.length}</td>

                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOfferToEdit(item);

                            setShowEditModalOffer(!showEditOfferModal);
                          }}
                        >
                          <i className="fas fa-pen icon-update" />
                        </td>

                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOfferId(item.id);

                            setShowDeleteOfferModal(!showDeleteOfferModal);
                          }}
                        >
                          <i className="fas fa-trash-alt icon-delete" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Button
            style={{ marginLeft: "15px", width: "200px" }}
            onClick={() => {
              history.push("/users");
            }}
          >
            Voltar
          </Button>
        </div>
      </div>

      <div id="sidebar-overlay" role="presentation" data-widget="pushmenu" onClick={toggleSideBar} />

      <ModalConfirmAction
        title="Deletar Oferta"
        bodyMessage="Você tem certeza que quer apagar esta oferta?"
        handleCloseModal={() => setShowDeleteOfferModal(!showDeleteOfferModal)}
        handleConfirmAction={() => handleDeleteOffer()}
        show={showDeleteOfferModal}
      />

      <ModalEditOffer
        show={showEditOfferModal}
        handleCloseModal={() => setShowEditModalOffer(!showEditOfferModal)}
        title={offerToEdit?.title}
        description={offerToEdit?.description}
        id={offerToEdit.id}
        files={offerToEdit.files}
        userId={offerToEdit.userId}
        price={offerToEdit.price}
        updateOffersState={updateOffersState}
      />

      <ModalCreateOffer
        show={showCreateOfferModal}
        handleCloseModal={() => setShowCreateOfferModal(!showCreateOfferModal)}
        userId={userId}
        addOfferState={addOfferState}
      />
    </>
  );
}

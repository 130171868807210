import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState, useCallback } from "react";
import { Modal, ModalProps, Card, Container, Row, Col, Spinner } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { api } from "../../service/api";
import { toastTopRightError } from "../../utils/toastConfig";
import { Input } from "./styles";
// import { Store, User } from '../../context/auth';

interface UserInfoModalProps extends ModalProps {
  userId: string;
}

interface IUsers {
  id: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

interface IAdress {
  id: string;
  cep: string;
  address: string;
  numberAddress: string;
  complementAddress: string;
  neighborhood: string;
  city: string;
  referencePointAddress: string;
  createdAt: string;
  updatedAt: string;
}

interface IRetailMarket {
  id: string;
  name: string;
  description: string;
  phone: string;
  email: string;
  cnpj: string;
  strAtendente: string | null;
  strPontoAtendimento: string | null;
  strLojista: string | null;
  strProduto: string | null;
  strOrigem: string | null;
  strCodigoEstabelecimento: string | null;
  showInSearch: true;
  createdAt: string;
  updatedAt: string;
}

type IData = Partial<{
  id: string;
  fantasyName: string;
  retailMarketId: string;
  addressId: string;
  socialReason: string;
  cnpj: string;
  ie: string;
  companyPhone1: string;
  companyPhone2: string;
  companyEmail: string;
  nameRespCompany: string;
  cpfRespCompany: string;
  phoneRespCompany: string;
  emailRespCompany: string;
  nameRespCompanyParcelize: string;
  cpfRespCompanyParcelize: string;
  phoneRespCompanyParcelize: string;
  emailRespCompanyParcelize: string;
  registrationStatus: number;
  status: string;
  active_status: true;
  isMain: false;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
  users: IUsers[];
  address: IAdress;
  retailMarket: IRetailMarket;
}>;

export function UserInfoModal({ userId, onHide, ...props }: UserInfoModalProps) {
  const [userInfoToShow, setUserInfoToShow] = useState<IData | null>();
  const [userNotesToShow, setUserNotesToShow] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getUserInfo() {
      if (!userId) {
        setUserInfoToShow(null);
        return;
      }

      try {
        setIsLoading(true);
        const response = await api.get<{ store: IData }>(`/store/${userId}`);

        const { data } = response;

        setUserInfoToShow(data.store);
        setUserNotesToShow(data.store.notes || "");
      } catch (e) {
        setIsLoading(false);
        toast("um erro aconteceu", toastTopRightError);
      } finally {
        setIsLoading(false);
      }
    }
    getUserInfo();
  }, [userId]);

  const saveNotes = useCallback(async () => {
    try {
      await api.put<{ storeUpdated: IData }>(`/store/${userInfoToShow?.id}`, {
        notes: userNotesToShow,
      });
    } catch (e) {
      toast("um erro aconteceu", toastTopRightError);
    }
  }, [userInfoToShow?.id, userNotesToShow]);

  async function onModalClose() {
    if (onHide) {
      onHide();
    }
    saveNotes();
  }

  return (
    <Modal
      dialogClassName={`modal-info ${isLoading ? "modal-loading" : ""}`}
      centered
      onHide={onHide}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {isLoading ? (
        <Spinner animation="border" variant="primary" className="spinner-load-modal" />
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`${userInfoToShow?.fantasyName} / `}

              {userInfoToShow?.cnpj}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <Card bg="light">
                <Card.Header>
                  <h5>Informações Gerais</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Nome Fantasia: </strong>

                          {userInfoToShow?.fantasyName}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        {" "}
                        <ListGroup.Item>
                          <strong>Razão Social: </strong>

                          {userInfoToShow?.socialReason}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Cnpj: </strong>

                          {userInfoToShow?.cnpj}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Inscrição Estadual: </strong>

                          {userInfoToShow?.ie}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Loja matriz: </strong>

                          {userInfoToShow?.isMain ? "Sim" : "Não"}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>

            {userInfoToShow?.retailMarket && (
              <ListGroup style={{ marginTop: "20px" }}>
                <Card bg="light">
                  <Card.Header>
                    <h5>Informações do varejo</h5>
                  </Card.Header>
                  <Card.Body>
                    <Container fluid>
                      <Row>
                        <Col>
                          <ListGroup.Item>
                            <strong>Nome: </strong>
                            {userInfoToShow?.retailMarket.name}
                          </ListGroup.Item>
                        </Col>
                        <Col>
                          <ListGroup.Item>
                            <strong>CNPJ: </strong>

                            {userInfoToShow?.retailMarket.cnpj}
                          </ListGroup.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ListGroup.Item>
                            <strong>E-mail: </strong>

                            {userInfoToShow?.retailMarket.email}
                          </ListGroup.Item>
                        </Col>
                        <Col>
                          <ListGroup.Item>
                            <strong>Telefone: </strong>

                            {userInfoToShow?.retailMarket.phone}
                          </ListGroup.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ListGroup.Item>
                            <strong>Descrição: </strong>

                            {userInfoToShow?.retailMarket.description}
                          </ListGroup.Item>
                        </Col>
                        <Col />
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </ListGroup>
            )}

            <ListGroup style={{ marginTop: "20px" }}>
              <Card bg="light">
                <Card.Header>
                  <h5>Informações de Contato</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Email: </strong>
                          {userInfoToShow?.companyEmail}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Telefone: </strong>

                          {userInfoToShow?.companyPhone1}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Telefone Corporativo 1: </strong>

                          {userInfoToShow?.companyPhone1}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Telefone Corporativo 2: </strong>

                          {userInfoToShow?.companyPhone2}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Email Corporativo 1: </strong>

                          {userInfoToShow?.companyPhone2}
                        </ListGroup.Item>
                      </Col>
                      <Col />
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>

            <ListGroup style={{ marginTop: "20px" }}>
              <Card bg="light">
                <Card.Header>
                  <h5>Informações do responsável</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Nome: </strong>
                          {userInfoToShow?.nameRespCompany}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>E-mail: </strong>

                          {userInfoToShow?.emailRespCompany}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>CPF: </strong>

                          {userInfoToShow?.cpfRespCompany}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Telefone: </strong>

                          {userInfoToShow?.phoneRespCompany}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>

            <ListGroup style={{ marginTop: "20px" }}>
              <Card bg="light">
                <Card.Header>
                  <h5>Informações do responsável Parcelize</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Nome: </strong>
                          {userInfoToShow?.nameRespCompanyParcelize}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>E-mail: </strong>

                          {userInfoToShow?.emailRespCompanyParcelize}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>CPF: </strong>

                          {userInfoToShow?.cpfRespCompanyParcelize}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Telefone: </strong>

                          {userInfoToShow?.phoneRespCompanyParcelize}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>

            <ListGroup title="teste" style={{ marginTop: "20px" }}>
              <Card bg="light">
                <Card.Header>
                  <h5>Endereço</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Cep: </strong>
                          {userInfoToShow?.address?.cep}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        {" "}
                        <ListGroup.Item>
                          <strong>Endereço: </strong>
                          {userInfoToShow?.address?.address}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Bairro: </strong>

                          {userInfoToShow?.address?.neighborhood}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Cidade: </strong>

                          {userInfoToShow?.address?.city}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Ponto de Referência: </strong>

                          {userInfoToShow?.address?.referencePointAddress}
                        </ListGroup.Item>
                      </Col>
                      <Col>
                        <ListGroup.Item>
                          <strong>Número: </strong>

                          {userInfoToShow?.address?.numberAddress}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>

            <ListGroup>
              <Card bg="light">
                <Card.Header>
                  <h5>Observações</h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <ListGroup.Item>
                          <strong>Observações:</strong>
                          <Input
                            value={userNotesToShow}
                            onChange={(e) => {
                              setUserNotesToShow(e.target.value);
                            }}
                          />
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onModalClose}>Ok</Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
